import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LiveCoach from '../../components/help/livecoach_main';
import ContactUs from '../../components/help/contact_us';
import CoachContactPhone from '../../components/help/CoachContactPhone';
import Faq from '../../components/help/faqs';
import {
  toggleLiveCoach,
  toggleContactUs,
  toggleFaq,
  sendEmail,
  loadHelpData,
  getCoachInfo
} from '../../actions/help';
import ToggleContainer from '../../components/toggle_container/toggle_container';
import configData from '../../config.json';
import Translate from '../../hocs/translate';
import './help-main.scss';
import helpBg from '../../../images/help-bg.png';

class Help extends Component {
  constructor() {
    super();

    this.liveCoachHandler = this.liveCoachHandler.bind(this);
    this.contactUsHandler = this.contactUsHandler.bind(this);
    this.faqHandler = this.faqHandler.bind(this);
    this.goToContactUs = this.goToContactUs.bind(this);
  }

  liveCoachHandler() {
    this.props.loadHelpData(this.props.userId);
    this.props.getCoachInfo(this.props.userId);
    this.props.toggleLiveCoach();
  }

  contactUsHandler() {
    this.props.toggleContactUs();
  }

  faqHandler() {
    this.props.toggleFaq();
  }

  goToContactUs() {
    if (!this.props.help.showLiveCoach) {
      this.liveCoachHandler();
    }

    findDOMNode(this.contactUs).scrollIntoView({ behavior: 'smooth' });
  }

  componentDidMount() {
    document.title = 'Cell-Ed Help • Essential Skills on the Go';
  }

  render() {
    const liteMode = configData.liteMode;

    return (
      <div className="help-page">
        <div className="help-page__main">
          <h1 className="help-page__title">{this.props.strings['How can Cell-Ed help you?']}</h1>
          <ToggleContainer
            title={this.props.strings['Contact my Coach']}
            show={this.props.help.showLiveCoach}
            toggle={this.liveCoachHandler}
            ref={(div) => {
              this.contactUs = div;
            }}>
            {this.props.help.coachInfo?.name && (
              <LiveCoach coachName={this.props.help.coachInfo.name} />
            )}
            <ContactUs
              userId={this.props.userId}
              sendEmail={this.props.sendEmail}
              emailSuccess={this.props.help.emailSuccess}
              useRTL={this.props.useRTL}
            />
            {this.props.help.coachInfo?.phoneNumber && (
              <CoachContactPhone coachPhone={this.props.help.coachInfo.phoneNumber} />
            )}
          </ToggleContainer>
          <ToggleContainer
            title={this.props.strings.Questions}
            show={this.props.help.showFaq}
            toggle={this.faqHandler}>
            <Faq goToContactUs={this.goToContactUs} />
          </ToggleContainer>
        </div>
        <div className="help-page__footer">
          {!liteMode && (
            <img className="help-page__footer-image" src={helpBg} role="presentation" alt="" />
          )}
        </div>
      </div>
    );
  }
}

Help.defaultProps = {
  strings: {
    'HELP PAGE': 'HELP PAGE',
    'How can Cell-Ed help you?': 'How can Cell-Ed help you?',
    'Contact my Coach': 'Contact my Coach',
    'Email my Coach': 'Email my Coach',
    Questions: 'Questions',
    'Text or Call my Coach': 'Text or Call my Coach'
  }
};

function mapStateToProps(state) {
  return { help: state.help, userId: state.mainApp.userId, useRTL: state.preferences.preferencesData.useRTL };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleLiveCoach,
      toggleContactUs,
      toggleFaq,
      sendEmail,
      loadHelpData,
      getCoachInfo
    },
    dispatch
  );
}

export default Translate('Help')(connect(mapStateToProps, mapDispatchToProps)(Help));
